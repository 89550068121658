import { createContext, useContext, useMemo, useState, useEffect, useRef } from 'react'
import { isObj } from '@prefect9/is-type'
import { useSplashScreen } from './SplashScreen'
import TgApi from './TgApi'
import ErrorScreen from './ErrorScreen'


const TgAppContext = createContext(null)


function TgApp(){
	const { loader, setError } = useSplashScreen()
	const [lib, setLib] = useState(null)


	const [loaded, setLoaded] = useState(false)


	const initRef = useRef(false)
	useEffect(() => {
		if(!isObj(initRef) || initRef.current !== false) return;
		initRef.current = true

		const tgApi = new TgApi()
		if(!tgApi.isSupported() && false) setError('The version of your Telegram is not supported')
		else{
			tgApi.ready()
			setLib(tgApi)
			setLoaded(true)
		}
	}, [initRef, setError])


	const contextValue = useMemo(() => ({ TgApi:lib }), [lib])
	const staticDataContent = useMemo(() => <ErrorScreen />, [])


	if(!loaded) return loader
	return (
		<TgAppContext.Provider value={contextValue}>
			{staticDataContent}
		</TgAppContext.Provider>
	)
}


const useTgApp = () => useContext(TgAppContext)


export default TgApp
export { useTgApp }