import qs from 'qs'
import axios from 'axios'
import { isStr, isFunc, isArr, isNum, isObj } from '@prefect9/is-type'
import { ParseApiResponse } from '@prefect9/helpers'


function TgApi(){
	//const that = this
	const TG = window.Telegram.WebApp
	const cryptoObj = window.crypto || window.msCrypto




	/* TELEGRAM API */
	this.isSupported = () => {
		return TG.isVersionAtLeast('6.1')
	}


	function HeaderColor(){
		this.now = null
		this.set = color => {
			if(TG.isVersionAtLeast('6.1')) TG.setHeaderColor(color)
			this.now = color
		}
	}
	this.headerColor = new HeaderColor()


	function BackButton(){

		this.isSupported = isStr(TG.initData) && TG.initData.length > 0

		let nowHandler = null
		this.removeHandler = () => {
			const wasHandler = nowHandler
			if(isFunc(wasHandler)) TG.BackButton.offClick(wasHandler)
			nowHandler = null
			return wasHandler
		}

		this.show = handler => {
			if(!this.isSupported) return;
			this.removeHandler()

			if(isFunc(handler)){
				TG.BackButton.onClick(handler)
				nowHandler = handler
			}
			
			TG.BackButton.show()
		}

		this.hide = () => {
			if(!this.isSupported) return;
			this.removeHandler()
			TG.BackButton.hide()
		}

	}
	this.backButton = new BackButton()


	this.init = (backButtonHandler=null, headerColor='default') => {
		this.headerColor.set(headerColor === 'default' ? '#000' : headerColor)
		if(TG.isVersionAtLeast('6.1')) TG.setBackgroundColor('#000')
		if(TG.isVersionAtLeast('7.7')) TG.disableVerticalSwipes()
		TG.expand()

		if(isFunc(backButtonHandler)) this.backButton.show(backButtonHandler)
		else this.backButton.hide()
	}

	this.ready = () => {
		this.init()
		TG.ready()
	}

	this.close = () => {
		TG.close()
	}

	this.openLink = url => {
		TG.openInvoice(url)
	}
	this.openTelegramLink = url => {
		TG.openTelegramLink(url)
	}

	function MainButton(){

		let nowHandler = null

		this.isSupported = isStr(TG.initData) && TG.initData.length > 0

		this.show = (handler, text='CONTINUE', loading=false, disabled=false) => {
			if(isFunc(nowHandler)){
				TG.MainButton.offClick(nowHandler)
				nowHandler = null
			}

			if(!this.isSupported) return;
			TG.MainButton.text = text
			
			if(loading || disabled){
				TG.MainButton.color = '#2f2f2f'
				TG.MainButton.textColor = '#5b5b5b'
			}else{
				TG.MainButton.color = '#66CC88'
				TG.MainButton.textColor = '#FFFFFF'
			}

			TG.MainButton.show()
			if(loading){
				TG.MainButton.disable()
				TG.MainButton.showProgress()
			}else if(disabled){
				TG.MainButton.hideProgress()
				TG.MainButton.disable()
			}else{
				TG.MainButton.hideProgress()
				TG.MainButton.enable()

				if(isFunc(handler)){
					TG.MainButton.onClick(handler)
					nowHandler = handler
				}
			}

		}
		this.hide = () => {
			if(isFunc(nowHandler)){
				TG.MainButton.offClick(nowHandler)
				nowHandler = null
			}

			if(!this.isSupported) return;
			TG.MainButton.hide()
		}

	}
	this.mainButton = new MainButton()


	function ScanQR(){

		this.supported = TG.isVersionAtLeast('6.4')

		this.start = (title, handler) => {
			TG.showScanQrPopup({ text:title }, result => {
				if(isFunc(handler)) handler(result)
				return true
			})
		}

	}
	this.scanQR = new ScanQR()




	/* BACKEND API */
	this.token = null
	const domain = process.env.REACT_APP_XCARD_API_DOMAIN

	this.rsaKeys = null
	this.getRsaKeys = async () => {
		if(!isObj(this.rsaKeys)){
			const keyPair = await cryptoObj.subtle.generateKey(
				{
					name: 'RSA-OAEP',
					modulusLength: 4096,
					publicExponent: new Uint8Array([1, 0, 1]),
					hash: 'SHA-256'
				},
				true,
				['encrypt', 'decrypt']
			)

			let publicKey = await cryptoObj.subtle.exportKey('spki', keyPair.publicKey)
			let publicKeyBinary = ''
			publicKey = new Uint8Array( publicKey )
			const publicKeyLength = publicKey.byteLength
			for (var i = 0; i < publicKeyLength; i++) publicKeyBinary += String.fromCharCode( publicKey[ i ] )
			publicKey = window.btoa(publicKeyBinary)
			let publicKeyPEM = '-----BEGIN PUBLIC KEY-----\n'
			while(publicKey.length > 0){
				publicKeyPEM += publicKey.substring(0,64) + '\n'
				publicKey = publicKey.substring(64)
			}
			publicKeyPEM += '-----END PUBLIC KEY-----\n'

			this.rsaKeys = { publicKeyCrypto:keyPair.publicKey, publicKeyPEM, privateKeyCrypto:keyPair.privateKey }
		}
		return this.rsaKeys
	}

	this.decodeRsaBase64 = async (base64, privateKey, label) => {
		const binaryString = window.atob(base64)
		const bytes = new Uint8Array(binaryString.length)
		for(let i = 0; i < binaryString.length; i++) bytes[i] = binaryString.charCodeAt(i)

		const labelBytes = new Uint8Array(label.length)
		for(let i = 0; i < label.length; i++) labelBytes[i] = label.charCodeAt(i)

		let decryptedBytes = await cryptoObj.subtle.decrypt(
			{ name:'RSA-OAEP', hash: 'SHA-256', label:labelBytes },
			privateKey,
			bytes
		)
		const decryptedLength = decryptedBytes.byteLength
		decryptedBytes = new Uint8Array(decryptedBytes)

		let result = ''
		for(let i = 0; i < decryptedLength; i++) result += String.fromCharCode( decryptedBytes[i] )
		return result
	}



	this.getBankCardBrand = bankCard => {
		const brands = {
			visa: 'VISA'
		}
		if(!Object.keys(brands).includes(bankCard.brand)) return 'N/A'
		return brands[bankCard.brand]
	}

	/*function parseResponse(response, object=null){
		const responseBody = response.data
		if(!isObj(responseBody)) throw new Error('Invalid response')

		if(responseBody.status === 'error'){
			if(isObj(responseBody.fields)) throw { status:'error_fields', fields:responseBody.fields, description:responseBody.message }
			else if(isStr(responseBody.message) && responseBody.message.trim().length > 0) throw { status:'error_text', description:responseBody.message }
			else throw { status:'error_text', description:'Undefined Error' }
		}

		if(responseBody.status !== 'ok') throw new Error('Invalid status response')

		if(isStr(object) && object.length > 0 && (!isObj(responseBody.data) || responseBody.data.object !== object)) throw new Error('Invalid response Object')

		return responseBody.data
	}*/

	this.getStaticData = async () => {
		const response = await axios.get(`${domain}/v1/static_data`, { headers: { 'X-Card-Bot': process.env.REACT_APP_XCARD_BOT, Authorization: `Bearer ${this.token}` } })
		return ParseApiResponse(response.data)
	}

	this.auth = async () => {
		const session = process.env.REACT_APP_XCARD_DEVELOPMENT === 'true' && (!isStr(TG.initData) || TG.initData.length < 1) ? process.env.REACT_APP_XCARD_DEVELOPMENT_SESSION : TG.initData
		const response = await axios.post(`${domain}/v1/telegram/auth`, qs.stringify({ session }), { headers: { 'X-Card-Bot': process.env.REACT_APP_XCARD_BOT } })
		return ParseApiResponse(response.data, 'user_session')
	}

	this.activateAccount = async inviteCode => {
		const response = await axios.post(`${domain}/v1/account/activate`, qs.stringify({ invite_code:inviteCode }), { headers: { 'X-Card-Bot': process.env.REACT_APP_XCARD_BOT, Authorization: `Bearer ${this.token}` } })
		return ParseApiResponse(response.data, 'user')
	}

	this.getBalance = async () => {
		const response = await axios.get(`${domain}/v1/account/balance`, { headers: { 'X-Card-Bot': process.env.REACT_APP_XCARD_BOT, Authorization: `Bearer ${this.token}` } })
		return ParseApiResponse(response.data)
	}

	this.updateAccount = async (firstName, lastName, email) => {
		const response = await axios.put(`${domain}/v1/account`, qs.stringify({ first_name:firstName, last_name:lastName, email }), { headers: { 'X-Card-Bot': process.env.REACT_APP_XCARD_BOT, Authorization: `Bearer ${this.token}` } })
		return ParseApiResponse(response.data, 'user')
	}

	this.updateAccountEmail = async email => {
		const response = await axios.put(`${domain}/v1/account/email`, qs.stringify({ email }), { headers: { 'X-Card-Bot': process.env.REACT_APP_XCARD_BOT, Authorization: `Bearer ${this.token}` } })
		return ParseApiResponse(response.data, 'user')
	}

	this.updateAccountName = async (firstName, lastName) => {
		const response = await axios.put(`${domain}/v1/account/name`, qs.stringify({ first_name:firstName, last_name:lastName }), { headers: { 'X-Card-Bot': process.env.REACT_APP_XCARD_BOT, Authorization: `Bearer ${this.token}` } })
		return ParseApiResponse(response.data, 'user')
	}

	this.updateAccountState = async (state, value) => {
		const response = await axios.put(`${domain}/v1/account/states/${state}`, qs.stringify({ value }), { headers: { 'X-Card-Bot': process.env.REACT_APP_XCARD_BOT, Authorization: `Bearer ${this.token}` } })
		return ParseApiResponse(response.data, 'user')
	}

	this.getTransactions = async () => {
		const response = await axios.get(`${domain}/v1/account/transactions`, { headers: { 'X-Card-Bot': process.env.REACT_APP_XCARD_BOT, Authorization: `Bearer ${this.token}` } })
		return ParseApiResponse(response.data, 'list')
	}

	this.getNotifications = async (supportedTypes, lastId) => {
		const params = {
			supported_types: isArr(supportedTypes) ? supportedTypes.join(',') : 'all'
		}
		if(isNum(lastId)) params.last_id = lastId

		const response = await axios.get(`${domain}/v1/account/notifications`, { headers: { 'X-Card-Bot': process.env.REACT_APP_XCARD_BOT, Authorization: `Bearer ${this.token}` }, params })
		return ParseApiResponse(response.data, 'list')
	}
	this.getNotification = async id => {
		const response = await axios.get(`${domain}/v1/account/notifications/${id}`, { headers: { 'X-Card-Bot': process.env.REACT_APP_XCARD_BOT, Authorization: `Bearer ${this.token}` } })
		return ParseApiResponse(response.data, 'notification')
	}

	this.withdraw = async requestBody => {
		const response = await axios.post(`${domain}/v1/account/withdraw`, qs.stringify(requestBody), { headers: { 'X-Card-Bot': process.env.REACT_APP_XCARD_BOT, Authorization: `Bearer ${this.token}` } })
		return ParseApiResponse(response.data)
	}

	this.getDepositWallet = async method => {
		const response = await axios.post(`${domain}/v1/account/deposit/wallet`, qs.stringify({ method }), { headers: { 'X-Card-Bot': process.env.REACT_APP_XCARD_BOT, Authorization: `Bearer ${this.token}` } })
		return ParseApiResponse(response.data, 'wallet_usdt_trc20')
	}

	this.getBankCardsTariffs = async () => {
		const response = await axios.get(`${domain}/v1/bank_cards/tariffs`, { headers: { 'X-Card-Bot': process.env.REACT_APP_XCARD_BOT } })
		return ParseApiResponse(response.data, 'list')
	}

	this.issueCards = async requestBody => {
		const response = await axios.post(`${domain}/v1/bank_cards`, qs.stringify(requestBody), { headers: { 'X-Card-Bot': process.env.REACT_APP_XCARD_BOT, Authorization: `Bearer ${this.token}` } })
		return ParseApiResponse(response.data)
	}

	this.getBankCards = async () => {
		const response = await axios.get(`${domain}/v1/bank_cards`, { headers: { 'X-Card-Bot': process.env.REACT_APP_XCARD_BOT, Authorization: `Bearer ${this.token}` } })
		return ParseApiResponse(response.data, 'list')
	}

	this.getBankCardsLimits = async () => {
		const response = await axios.get(`${domain}/v1/bank_cards/limits`, { headers: { 'X-Card-Bot': process.env.REACT_APP_XCARD_BOT, Authorization: `Bearer ${this.token}` } })
		return ParseApiResponse(response.data)
	}

	this.getBankCard = async id => {
		const response = await axios.get(`${domain}/v1/bank_cards/${id}`, { headers: { 'X-Card-Bot': process.env.REACT_APP_XCARD_BOT, Authorization: `Bearer ${this.token}` } })
		return ParseApiResponse(response.data, 'bank_card')
	}

	this.setBankCardLimit = async (id, amount) => {
		const response = await axios.post(`${domain}/v1/bank_cards/${id}/limit`, qs.stringify({ amount }), { headers: { 'X-Card-Bot': process.env.REACT_APP_XCARD_BOT, Authorization: `Bearer ${this.token}` } })
		return ParseApiResponse(response.data, 'bank_card')
	}

	this.removeBankCardLimit = async id => {
		const response = await axios.delete(`${domain}/v1/bank_cards/${id}/limit`, { headers: { 'X-Card-Bot': process.env.REACT_APP_XCARD_BOT, Authorization: `Bearer ${this.token}` } })
		return ParseApiResponse(response.data, 'bank_card')
	}

	this.getBankCardRequisites = async id => {
		const { publicKeyPEM, privateKeyCrypto } = await this.getRsaKeys()

		const response = await axios.get(`${domain}/v1/bank_cards/${id}/requisites?publicKey=${window.btoa(publicKeyPEM)}`, { headers: { 'X-Card-Bot': process.env.REACT_APP_XCARD_BOT, Authorization: `Bearer ${this.token}` } })
		const requisites = ParseApiResponse(response.data, 'bank_card_requisites')

		const result = {}
		result.number = await this.decodeRsaBase64(requisites.card_number_encrypted, privateKeyCrypto, 'BankCardNumber')
		result.password_3ds = await this.decodeRsaBase64(requisites.card_3ds_password_encrypted, privateKeyCrypto, 'BankCard3DSPassword')
		result.cvv = await this.decodeRsaBase64(requisites.card_cvv_encrypted, privateKeyCrypto, 'BankCardCVV')
		return result
	}

	this.lockBankCard = async id => {
		const response = await axios.post(`${domain}/v1/bank_cards/${id}/lock`, null, { headers: { 'X-Card-Bot': process.env.REACT_APP_XCARD_BOT, Authorization: `Bearer ${this.token}` } })
		return ParseApiResponse(response.data, 'bank_card')
	}

	this.unlockBankCard = async id => {
		const response = await axios.post(`${domain}/v1/bank_cards/${id}/unlock`, null, { headers: { 'X-Card-Bot': process.env.REACT_APP_XCARD_BOT, Authorization: `Bearer ${this.token}` } })
		return ParseApiResponse(response.data, 'bank_card')
	}

	this.archiveBankCard = async id => {
		const response = await axios.post(`${domain}/v1/bank_cards/${id}/archive`, null, { headers: { 'X-Card-Bot': process.env.REACT_APP_XCARD_BOT, Authorization: `Bearer ${this.token}` } })
		return ParseApiResponse(response.data, 'bank_card')
	}

	this.addBankCardTag = async (id, text) => {
		const response = await axios.post(`${domain}/v1/bank_cards/${id}/tags`, qs.stringify({ text }), { headers: { 'X-Card-Bot': process.env.REACT_APP_XCARD_BOT, Authorization: `Bearer ${this.token}` } })
		return ParseApiResponse(response.data, 'bank_card')
	}

	this.editBankCardTag = async (id, tagId, text) => {
		const response = await axios.put(`${domain}/v1/bank_cards/${id}/tags/${tagId}`, qs.stringify({ text }), { headers: { 'X-Card-Bot': process.env.REACT_APP_XCARD_BOT, Authorization: `Bearer ${this.token}` } })
		return ParseApiResponse(response.data, 'bank_card')
	}

	this.deleteBankCardTag = async (id, tagId) => {
		const response = await axios.delete(`${domain}/v1/bank_cards/${id}/tags/${tagId}`, { headers: { 'X-Card-Bot': process.env.REACT_APP_XCARD_BOT, Authorization: `Bearer ${this.token}` } })
		return ParseApiResponse(response.data, 'bank_card')
	}

	this.getBankCardTransactions = async (id, tagId) => {
		const response = await axios.get(`${domain}/v1/bank_cards/${id}/transactions`, { headers: { 'X-Card-Bot': process.env.REACT_APP_XCARD_BOT, Authorization: `Bearer ${this.token}` } })
		return ParseApiResponse(response.data, 'list')
	}

	this.transfer = async requestBody => {
		const response = await axios.post(`${domain}/v1/transfer`, requestBody, { headers: { 'X-Card-Bot': process.env.REACT_APP_XCARD_BOT, Authorization: `Bearer ${this.token}` } })
		return ParseApiResponse(response.data)
	}

}

export default TgApi

