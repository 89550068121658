import { createContext, useContext, useState, useMemo, useEffect, useCallback } from 'react'
import { Link } from 'react-router-dom'
import { useSplashScreen } from './SplashScreen'
import { useTgApp } from './TgApp'
import { useUser } from './User'
import { usePush } from './Push'
import { useModal } from './Modal'
import { isStr, isArr, ofArr, isFunc } from '@prefect9/is-type'
import renderOtpModal from '../modals/OtpModal'
import Num from '@prefect9/num'
import Router from '../layout/Router'

import { ReactComponent as NotificationSvg } from '../icons/Notification.svg'


const NotificationsContext = createContext(null)


const supportedNotificationsTypes = [
	'withdraw_declined',
	'withdraw_completed',
	'deposit_usdt_trc20_confirmation',
	'deposit_usdt_trc20_confirmed',
	'deposit_usdt_trc20_error',
	'bank_card_transaction_payment',
	'bank_card_transaction_declined'
]
function isSupportedNotification(type){
	return supportedNotificationsTypes.includes(type)
}
function getNotificationTitle(notification){
	const type = notification.type
	if(type === 'withdraw_declined') return 'The withdrawal of funds was refused'
	if(type === 'withdraw_completed') return 'The withdrawal of funds has been completed'
	if(type === 'deposit_usdt_trc20_confirmation') return 'A deposit USDT (TRC-20) has been found'
	if(type === 'deposit_usdt_trc20_confirmed') return 'Confirmed deposit in USDT (TRC-20)'
	if(type === 'deposit_usdt_trc20_error') return 'Error when processing a deposit in USDT (TRC-20)'
	if(type === 'bank_card_transaction_payment'){
		return `Card *${notification.bank_card_transaction.bank_card.last4} successful transaction`
	}
	if(type === 'bank_card_transaction_declined'){
		return `Card *${notification.bank_card_transaction.bank_card.last4} declined transaction`
	}
	return null
}
function getNotificationDescription(notification){
	const type = notification.type
	if(type === 'withdraw_declined'){
		const withdraw = notification.withdraw

		let currency = 'NaN'
		if(withdraw.method === 'usdt_trc20') currency = 'USDT (TRC-20)'

		return <span>The withdrawal of funds in the amount of <b>{Num.toAmount(withdraw.amount)} {currency}</b> was rejected</span>
	}
	if(type === 'withdraw_completed'){
		const withdraw = notification.withdraw

		let currency = 'NaN'
		if(withdraw.method === 'usdt_trc20') currency = 'USDT (TRC-20)'

		return <span>The withdrawal of funds in the amount of <b>{Num.toAmount(withdraw.amount)} {currency}</b> has been sent</span>
	}
	if(type === 'deposit_usdt_trc20_confirmation'){
		const deposit = notification.deposit
		return <span>Replenishment in the amount of <b>{Num.toAmount(deposit.amount)} USDT (TRC-20)</b> is awaiting 20 network confirmations. After confirmation, the funds will be credited to your balance</span>
	}
	if(type === 'deposit_usdt_trc20_confirmed'){
		const deposit = notification.deposit
		return <span>Replenishment in the amount of <b>{Num.toAmount(deposit.amount)} USDT (TRC-20)</b> has received a sufficient number of confirmations. The money has been credited to your balance</span>
	}
	if(type === 'deposit_usdt_trc20_error'){
		const deposit = notification.deposit
		return <span>An error occurred while processing a transaction in the amount of <b>{Num.toAmount(deposit.amount)} USDT (TRC-20)</b></span>
	}
	if(type === 'bank_card_transaction_payment'){
		const transaction = notification.bank_card_transaction
		const bankCard = transaction.bank_card
		return <span>Payment by <b>card *{bankCard.last4}</b> on the amount of <b>{Num.toAmount(transaction.total)} USD</b> was approved, merchant {transaction.merchant_name}</span>
	}
	if(type === 'bank_card_transaction_declined'){
		const transaction = notification.bank_card_transaction
		const bankCard = transaction.bank_card
		return <span>Payment by <b>card *{bankCard.last4}</b> on the amount of <b>{Num.toAmount(transaction.total)} USD</b> was rejected, merchant {transaction.merchant_name}</span>
	}
	return null
}


function Notifications(){
	const { loader, setError } = useSplashScreen()
	const { refreshBalance, refreshCardsLimits } = useUser()
	const { TgApi } = useTgApp()
	const { notification:notificationPush } = usePush()
	const { showModal } = useModal()


	const [loaded, setLoaded] = useState(false)
	const [hasNotViewed, setHasNotViewed] = useState(false)


	const [handlers, setHandlers] = useState([])
	const addHandler = useCallback(handler => {
		if(!isFunc(handler)) throw new Error('Handler must be function')
		setHandlers(state => {
			const result = [...ofArr(state)]
			result.push(handler)
			return result
		})
	}, [])
	const removeHandler = useCallback(handler => setHandlers(state => {
		const result = []
		for(let item of ofArr(state)){
			if(item === handler) continue;
			result.push(item)
		}
		return result
	}), [])


	const [notifications, setNotifications] = useState([])

	useEffect(() => { // call handlers
		for(let notification of notifications){
			if(!notification.handlerCalled) continue;

			notification.handlerCalled = false
			for(let handler of handlers) handler(notification)
		}
	}, [notifications, handlers])


	const viewedNotification = useCallback(id => {
		let notViewed = false
		for(let notification of notifications){
			if(notification.id !== id) continue;
			if(notification.viewed !== true) notViewed = true
			break;
		}
		if(notViewed) setNotifications(state => {
			const result = []
			for(let notification of ofArr(state)){
				if(notification.id !== id) result.push(notification)
				else result.push({...notification, viewed:true})
			}
			return result
		})
	}, [notifications])


	useEffect(() => {
		let loaded = false
		let lastId = null
		let destructed = false
		const Refresh = () => {
			if(destructed) return;

			TgApi.getNotifications(supportedNotificationsTypes, lastId)
			.then(data => {
				if(destructed) return;

				setHasNotViewed(data.has_not_viewed)

				let newNotifications = []
				for(let notification of ofArr(data.list)){
					notification.frontendPush = loaded
					notification.handlerCalled = loaded
					const { type, otp } = notification

					if(loaded){ // для новых уведомлений в реалтайме
						if(type === 'withdraw_created') refreshBalance()
						if(type === 'deposit_usdt_trc20_confirmed') refreshBalance()
						if(type === 'bank_card_issue'){
							refreshBalance()
							refreshCardsLimits()
						}
						if(type === 'bank_card_otp') showModal(renderOtpModal(otp))
					}

					newNotifications.unshift(notification)
				}
				if(isArr(data.list) && data.list.length > 0) lastId = data.list[0].id
				if(newNotifications.length) setNotifications(state => {
					const result = [...ofArr(state)]
					for(let notification of newNotifications) result.unshift(notification)
					return result
				})

				if(!loaded){
					loaded = true
					setLoaded(true)
				}
				setTimeout(Refresh, 2500)
			})
			.catch(e => {
				setTimeout(Refresh, 2500)
			})
		}
		Refresh()

		return () => {
			destructed = true
		}
	}, [TgApi, setError, refreshBalance, refreshCardsLimits, showModal])


	const supportedNotifications = useMemo(() => {
		const result = []
		for(let notification of notifications){
			if(!isSupportedNotification(notification.type)) continue;
			notification.frontendTitle = getNotificationTitle(notification)
			notification.frontendDescription = getNotificationDescription(notification)

			result.push(notification)
		}
		return result
	}, [notifications])

	useEffect(() => { // show push notifications
		for(let notification of supportedNotifications){
			if(!notification.frontendPush) continue;

			notification.frontendPush = false
			notificationPush(notification.frontendTitle, notification.frontendDescription)
		}
	}, [supportedNotifications, notificationPush])


	const contextValue = useMemo(() => ({ notifications:supportedNotifications, hasNotViewed, viewedNotification, addHandler, removeHandler }), [supportedNotifications, hasNotViewed, viewedNotification, addHandler, removeHandler])

	const router = useMemo(() => <Router />, [])


	if(!loaded) return loader
	return (
		<NotificationsContext.Provider value={contextValue}>
			{router}
		</NotificationsContext.Provider>
	)

}


const useNotifications = () => useContext(NotificationsContext)


function NotificationsIco({ from }){
	const { hasNotViewed } = useNotifications()

	return (
		<Link to={`/notifications${isStr(from) && from.trim().length ? `?from=${from}` : ''}`} className={`notifications-ico ${hasNotViewed ? 'active' : ''}`}>
			<NotificationSvg />
			<div className='notifications-ico__active' />
		</Link>
	)

}


export default Notifications
export { useNotifications, NotificationsIco }