import { useMemo } from 'react'
import { Outlet, Navigate, useLocation } from 'react-router-dom'
import { isStr, isTrue } from '@prefect9/is-type'
import { useUser } from '../lib/User'


function BaseAccount(){
	const { user } = useUser()

	const location = useLocation()
	const query = useMemo(() => new URLSearchParams(isStr(location.search) ? location.search : ''), [location])


	if(location.pathname !== '/activation' && !isTrue(user.registered)){
		if(query.get('skipAnimation') === 'true') return <Navigate to='/activation?skipAnimation=true' replace={true} />
		return <Navigate to='/activation' replace={true} />
	}
	if(location.pathname === '/activation' && isTrue(user.registered)) return <Navigate to='/' replace={true} />
	if(!isTrue(user.activated) && /^\/cards(\/.*)?$/.test(location.pathname)) return <Navigate to='/' replace={true} />
	return <Outlet />

}
export default BaseAccount