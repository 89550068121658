import { useRef, useEffect, useMemo, useCallback } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useSplashScreen } from '../lib/SplashScreen'
import { useUser } from '../lib/User'
import { useTgApp } from '../lib/TgApp'
import { useModal } from '../lib/Modal'
import { NotificationsIco } from '../lib/Notifications'
import { isObj, isStr, isTrue } from '@prefect9/is-type'
import AccountTransactions from '../components/AccountTransactions'
import renderAlertModal from '../modals/AlertModal'

import { ReactComponent as XLogoWhiteSvg } from '../icons/XLogoWhite.svg'
import { ReactComponent as ArrowBottomCircleSvg } from '../icons/ArrowBottomCircle.svg'
import { ReactComponent as CardSvg } from '../icons/Card.svg'
import { ReactComponent as ArrowTopCircleSvg } from '../icons/ArrowTopCircle.svg'
import { ReactComponent as AccountSvg } from '../icons/Account.svg'
import { ReactComponent as ArrowRightSvg } from '../icons/ArrowRight.svg'
import { ReactComponent as CoinsSvg } from '../icons/Coins.svg'
import { ReactComponent as TelegramSvg } from '../icons/Telegram.svg'


function MainPage(){
	const { setLoadedApp } = useSplashScreen()
	const { user, name, balance } = useUser()
	const { TgApi } = useTgApp()
	const { showModal } = useModal()
	const navigate = useNavigate()

	const location = useLocation()
	const query = useMemo(() => new URLSearchParams(isStr(location.search) ? location.search : ''), [location])


	useEffect(() => TgApi.init(), [TgApi])


	const initRef = useRef(false)
	const pageRef = useRef(null)
	useEffect(() => {
		const paddingTop = '64px'

		if(!isObj(initRef) || initRef.current !== false) return;
		initRef.current = true

		if(!isObj(pageRef) || !isObj(pageRef.current)) return;
		const page = pageRef.current
		const pageTop = page.getBoundingClientRect().top

		const loader = page.querySelector('.page-with-loader__loader')
		const loaderTop = loader.getBoundingClientRect().top

		const pageContainer = page.querySelector('.page-with-loader__container')


		if(query.get('skipAnimation') === 'true'){
			setLoadedApp(true)
			page.classList.add('loader__skip')
			loader.style.display = 'none'
			pageContainer.style.display = 'flex'
			pageContainer.style.transition = 'unset'
			pageContainer.style.paddingTop = paddingTop
			return;
		}


		setTimeout(() => setLoadedApp(true), 100)
		setTimeout(() => {
			page.classList.add('loader__start')
			loader.style.display = 'none'
			pageContainer.style.display = 'flex'
			pageContainer.style.paddingTop = `${loaderTop-pageTop}px`

			setTimeout(() => {
				pageContainer.classList.add('loader__step-1')
			}, 30)

			setTimeout(() => {
				pageContainer.classList.add('loader__step-2')
			}, 830)

			setTimeout(() => {
				pageContainer.classList.add('loader__step-3')
				pageContainer.style.paddingTop = paddingTop
			}, 1500)
		}, 200)
	}, [initRef, pageRef, query, setLoadedApp])


	const cardsHandler = useCallback(e => {
		e.preventDefault()

		if(!isTrue(user.activated)) showModal(renderAlertModal('Account not activated', 'To activate your account, get an invite code from support and enter it in your profile settings'))
		else navigate('/cards')
	}, [navigate, user, showModal])


	const withdrawHandler = useCallback(e => {
		e.preventDefault()
		navigate('/withdraw')
	}, [navigate])


	const supportHandler = useCallback(e => {
		e.preventDefault()
		TgApi.openTelegramLink('https://t.me/xpay_app_support')
	})


	return (
		<div ref={pageRef} className='page-with-loader page__main'>
			<div className='loader page-with-loader__loader'><div className='loader__circle' /></div>
			<div className='page-with-loader__container'>
				<div className='loader'>
					<div className='loader__circle'><XLogoWhiteSvg /></div>
					<div className='loader__text m28'><div>Pay</div></div>
				</div>
				<div className='page-with-loader__body'>
					<NotificationsIco />
					<a href='https://t.me/xpay_app_support' target='_blank' className='main__support-btn'><TelegramSvg /><div>Support</div></a>
					<div className='m15 main__balance-text'>Balance</div>
					<div className='main__balance'><div>{balance.beforeDot},</div><div>{balance.afterDot}</div><div>$</div></div>
					<div className='main__btns'>
						<Link to='/deposit'>
							<div className='main__btn'>
								<ArrowBottomCircleSvg />
								<div className='m13'>Top up</div>
							</div>
						</Link>
						<div>
							<div className='main__btn' onClick={cardsHandler}>
								<CardSvg />
								<div className='m13'>My cards</div>
							</div>
						</div>
						<div>
							<div className='main__btn' onClick={withdrawHandler}>
								<ArrowTopCircleSvg />
								<div className='m13'>Withdraw</div>
							</div>
						</div>
					</div>
					<Link to='/profile' className='main__card main__card-account'>
						<div className='main__card-ico'><AccountSvg /></div>
						<div className='main__card-content'>
							<div className='m15'>{name}</div>
							<div className='t12'>{user.email}</div>
						</div>
						<ArrowRightSvg className='main__card-arrow' />
					</Link>
					{/*<div className='main__card main__card-staking'>
						<div className='main__card-ico'><CoinsSvg /></div>
						<div className='main__card-content'>
							<div className='m15'>Staking</div>
							<div className='t12'>Coming soon...</div>
						</div>
						<div className='main__card-subcontent'>
							<div className='m15'>+0.00 USD</div>
							<div className='t12'>per day</div>
						</div>
					</div>*/}
					<AccountTransactions />
				</div>
			</div>
		</div>
	)

}
export default MainPage