function AlertModal({ resolve, reject, title, text }){

	return (
		<div className='modal-body modal-alert'>
			<div className='alert__ico'></div>
			<div className='modal__title m20'>{title}</div>
			<div className='modal__text t12'>{text}</div>
			<div className='modal__close m15' onClick={() => resolve('close')}>Close</div>
		</div>
	)

}


function renderAlertModal(title, text){
	return (resolve, reject) => ({ modal: <AlertModal resolve={resolve} reject={reject} title={title} text={text} /> })
}


export default renderAlertModal