import { useRef, useEffect, useMemo, useState, useCallback } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useSplashScreen } from '../lib/SplashScreen'
import { Validators, useTextFieldV2 } from '@prefect9/ui'
import { useTgApp } from '../lib/TgApp'
import { useUser } from '../lib/User'
import { isObj, isTrue, isStr, isFunc } from '@prefect9/is-type'
import { ParseApiCatch } from '@prefect9/helpers'

import { ReactComponent as XLogoWhite } from '../icons/XLogoWhite.svg'


function ActivationPage(){
	const { TgApi } = useTgApp()
	const { setLoadedApp } = useSplashScreen()
	const { setUser } = useUser()

	const location = useLocation()
	const query = useMemo(() => new URLSearchParams(isStr(location.search) ? location.search : ''), [location])


	const [error, setError] = useState(null)


	const [animationFinished, setAnimationFinished] = useState(false)


	const initRef = useRef(false)
	const pageRef = useRef(null)
	useEffect(() => {
		if(!isObj(initRef) || initRef.current !== false) return;
		initRef.current = true

		const paddingTop = '110px'

		if(!isObj(pageRef) || !isObj(pageRef.current)) return;
		const page = pageRef.current
		const pageTop = page.getBoundingClientRect().top

		const loader = page.querySelector('.page-with-loader__loader')
		const loaderTop = loader.getBoundingClientRect().top

		const pageContainer = page.querySelector('.page-with-loader__container')

		if(query.get('skipAnimation') === 'true'){
			setLoadedApp(true)
			setAnimationFinished(true)
			page.classList.add('loader__skip')
			loader.style.display = 'none'
			pageContainer.style.display = 'flex'
			pageContainer.style.transition = 'unset'
			pageContainer.style.paddingTop = paddingTop
			return;
		}
		
		setTimeout(() => setLoadedApp(true), 100)
		setTimeout(() => {
			page.classList.add('loader__start')
			loader.style.display = 'none'
			pageContainer.style.display = 'flex'
			pageContainer.style.paddingTop = `${loaderTop-pageTop}px`

			setTimeout(() => {
				pageContainer.classList.add('loader__step-1')
			}, 30)

			setTimeout(() => {
				pageContainer.classList.add('loader__step-2')
			}, 830)

			setTimeout(() => {
				pageContainer.classList.add('loader__step-3')
				pageContainer.style.paddingTop = paddingTop
				setAnimationFinished(true)
			}, 1500)
		}, 200)
	}, [query, pageRef, setLoadedApp])


	const [loading, setLoading] = useState(false)


	const [firstNameValue, setFirstNameValue] = useState('')
	const firstName = useTextFieldV2({
		value:firstNameValue,
		onEdit:setFirstNameValue,
		validators:useMemo(() => [Validators.required('First name is required'), Validators.name('It can contain only English letters'), Validators.maxLength(30, 'Max length is 30 chars')], [])
	})


	const [lastNameValue, setLastNameValue] = useState('')
	const lastName = useTextFieldV2({
		value:lastNameValue,
		onEdit:setLastNameValue,
		validators:useMemo(() => [Validators.name('It can contain only English letters'), Validators.maxLength(30, 'Max length is 30 chars')], [])
	})


	const [emailValue, setEmailvalue] = useState('')
	const email = useTextFieldV2({
		value:emailValue,
		onEdit:setEmailvalue,
		validators:useMemo(() => [Validators.required('Email is required'), Validators.email('Invalid email address'), Validators.maxLength(150, 'Max length is 150 chars')], [])
	})


	const valid = useMemo(() => isTrue(firstName.anyValid) && isTrue(lastName.anyValid) && isTrue(email.anyValid), [firstName.anyValid, lastName.anyValid, email.anyValid])


	const submitHandler = useCallback(e => {
		if(isObj(e) && isFunc(e.preventDefault)) e.preventDefault()
		setLoading(true)

		TgApi.updateAccount(firstNameValue, lastNameValue, emailValue)
		.finally(() => setLoading(false))
		.then(setUser)
		.catch(e => {
			ParseApiCatch(e, {
				first_name:firstName.setError,
				last_name:lastName.setError,
				email:email.setError
			}, setError)
		})
	}, [TgApi, setUser, firstNameValue, lastNameValue, emailValue, firstName.setError, lastName.setError, email.setError])


	useEffect(() => {
		if(!animationFinished) return;
		TgApi.mainButton.show(submitHandler, loading ? 'WAIT' : 'CONTINUE', loading, !valid)
	}, [TgApi, animationFinished, submitHandler, loading, valid])

	useEffect(() => {
		TgApi.init()
		return () => {
			TgApi.mainButton.hide()
		}
	}, [TgApi])


	return (
		<div ref={pageRef} className='page-with-loader page__activation'>
			<div className='loader page-with-loader__loader'><div className='loader__circle' /></div>
			<div className='page-with-loader__container'>
				<div className='loader'>
					<div className='loader__circle'><XLogoWhite /></div>
					<div className='loader__text m28'><div>Pay</div></div>
				</div>
				<div className='page-with-loader__body'>
					<div className='m30 center'>Personal Information</div>
					<div className='m15 center'>Only one step left to issue cards</div>
					<form className='form' onSubmit={submitHandler}>
						<div className={`field ${!isTrue(firstName.valid) ? 'field-invalid' : ''}`}>
							<label htmlFor={firstName.id}>First name*</label>
							<input id={firstName.id} value={firstNameValue} onInput={firstName.editHandler} placeholder='Required' autoComplete='off' disabled={loading} />
							{isStr(firstName.valid) ? <div className='field-error'>{firstName.valid}</div> : null}
						</div>
						<div className={`field ${!isTrue(lastName.valid) ? 'field-invalid' : ''}`}>
							<label htmlFor={lastName.id}>Last name</label>
							<input id={lastName.id} value={lastNameValue} onInput={lastName.editHandler} placeholder='Required' autoComplete='off' disabled={loading} />
							{isStr(lastName.valid) ? <div className='field-error'>{lastName.valid}</div> : null}
						</div>
						<div className={`field ${!isTrue(email.valid) ? 'field-invalid' : ''}`}>
							<label htmlFor={email.id}>Email*</label>
							<input type='email' id={email.id} value={emailValue} onInput={email.editHandler} placeholder='email@example.com' autoComplete='off' disabled={loading} />
							{isStr(email.valid) ? <div className='field-error'>{email.valid}</div> : null}
						</div>
						{isStr(error) ? <div className='form-error'>{error}</div> : null}
						<div className='form-section'>
							<div className='m13 page__activation-text center'>By providing personal information, you agree to our <span classList='green'>terms of use</span> and <span classList='green'>privacy policy</span></div>
							<div className='page__activation-btns'>
								<Link className='btn white-gray' to='/terms'>Terms of use</Link>
								<Link className='btn white-gray' to='/privacy'>Privacy policy</Link>
							</div>
							{!TgApi.mainButton.isSupported ? <button type='submit' className='btn white-green page__activation-submit' disabled={!valid || loading}>{loading ? 'WAIT' : 'CONTINUE'}</button> : null}
						</div>
					</form>
				</div>
			</div>
		</div>
	)

}
export default ActivationPage