import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { isStr, isNum } from '@prefect9/is-type'
import DateTime from '@prefect9/datetime'

import { ReactComponent as CardPlusSvg } from '../icons/CardPlus.svg'


function AccountTransactionBankCardIssue({ transaction }){

	const bankCard = transaction.bank_card
	const date = useMemo(() => {
		const result = new DateTime(transaction.date_create)
		return result.toInterfaceStr()
	}, [transaction])

	const [statusClassName, statusText] = useMemo(() => {
		const statuses = {
			paid: ['account-transaction__bank-card-issue-paid', 'Paid'],
			refunded: ['account-transaction__bank-card-issue-refunded', 'Refunded']
		}
		if(bankCard.status === 'processing_aborted') return statuses['refunded']
		return statuses['paid']
	}, [bankCard])

	const content = useMemo(() => {
		return (
			<>
				<div className='account-transaction__ico'><CardPlusSvg /></div>
				<div className='account-transaction__data'>
					<div className='m14'>Card issue</div>
					<div className='t10'>{date}</div>
				</div>
				<div className='account-transaction__amount'>
					<div className='m14'>-{bankCard.tariff.issue_price} USD</div>
					<div className='t10'>{statusText}</div>
				</div>
			</>
		)
	}, [date, bankCard, statusText])


	if(isStr(transaction.notification) || isNum(transaction.notification)) return <Link className={`account-transaction account-transaction__bank-card-issue ${statusClassName}`} to={`/notifications/${transaction.notification}`}>{content}</Link>
	return <div className={`account-transaction account-transaction__bank-card-issue ${statusClassName}`}>{content}</div>

}
export default AccountTransactionBankCardIssue