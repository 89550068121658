import { createContext, useContext, useMemo, useState, useRef, useEffect, useCallback } from 'react'
import { useSplashScreen } from './SplashScreen'
import { useTgApp } from './TgApp'
import { isObj, isStr } from '@prefect9/is-type'
import Notifications from './Notifications'


const UserContext = createContext(null)


function User(){
	const { loader, setError } = useSplashScreen()
	const { TgApi } = useTgApp()


	const [loaded, setLoaded] = useState(false)
	const [user, setUser] = useState(null)


	const name = useMemo(() => {
		if(!isObj(user)) return 'Undefined'

		const result = []
		if(isStr(user.first_name) && user.first_name.trim().length > 0) result.push(user.first_name)
		if(isStr(user.last_name) && user.last_name.trim().length > 0) result.push(user.last_name)
		if(!result.length) return 'No Name'
		return result.join(' ')
	}, [user])

	const balance = useMemo(() => {
		if(!isObj(user)) return { beforeDot:'null', afterDot:'null', str:'null' }
		const balance = parseFloat(user.balance)

		const result = {}
		result.amount = balance

		const beforeDot = parseInt(balance)
		result.beforeDot = `${beforeDot}`

		let afterDot = parseInt(balance * 100) % 100
		afterDot = `${afterDot}`
		if(afterDot.length === 1) afterDot = `0${afterDot}`
		result.afterDot = `${afterDot}`

		result.str = `${beforeDot}.${afterDot}`
		return result
	}, [user])

	const refreshBalance = useCallback(() => {
		return new Promise((resolve, reject) => {

			TgApi.getBalance()
			.then(data => {
				setUser(nowUser => {
					if(!isObj(nowUser)) return nowUser
					return {...nowUser, balance:data}
				})
				resolve(data)
			})
			.catch(reject)

		})
	}, [TgApi])

	const refreshCardsLimits = useCallback(() => {
		TgApi.getBankCardsLimits()
		.then(data => setUser(nowUser => {
			if(!isObj(nowUser)) return nowUser
			return {...nowUser, cards_limits:data}
		}))
		.catch(e => { throw e })
	}, [TgApi])


	const contextValue = useMemo(() => ({ user, name, balance, refreshBalance, refreshCardsLimits, setUser, updateUser:setUser }), [user, name, balance, refreshBalance, refreshCardsLimits])


	const initRef = useRef(false)
	useEffect(() => {
		if(!isObj(initRef) || initRef.current !== false) return;
		initRef.current = true
	
		TgApi.auth()
		.then(data => {
			if(!isObj(data) || data.object !== 'user_session') throw new Error('Invalid user session object')

			TgApi.token = data.token

			setUser(data.user)
			setLoaded(true)
		})
		.catch(e => {
			console.dir(e)
			setError('An error occurred during authorization')
		})
	}, [initRef, TgApi, setError, setUser])


	const content = useMemo(() => <Notifications />, [])


	if(!loaded) return loader
	return (
		<UserContext.Provider value={contextValue}>
			{content}
		</UserContext.Provider>
	)

}


const useUser = () => useContext(UserContext)


export default User
export { useUser }