import { useRef, useEffect } from 'react'
import { isObj } from '@prefect9/is-type'

import { ReactComponent as CopySvg } from '../icons/Copy.svg'


function CopyPush({ onDelete:deleteHandler, k, message }){
	
	const initRef = useRef(false)
	const pushRef = useRef(null)
	useEffect(() => {
		if(!isObj(pushRef) || !isObj(pushRef.current)) return;
		const push = pushRef.current

		if(!isObj(initRef) || initRef.current !== false) return;
		initRef.current = true

		setTimeout(() => push.classList.add('show'), 10)
		setTimeout(() => push.classList.add('hide'), 2500)
		setTimeout(deleteHandler, 2900)
	}, [k, deleteHandler])


	return (
		<div ref={pushRef} className='push push-copy t14'>
			<CopySvg />
			<div className='push-text'>{message}</div>
		</div>
	)

}
export default CopyPush