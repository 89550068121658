import { createContext, useContext, useMemo, useState, useRef, useEffect } from 'react'
import { isStr, isObj } from '@prefect9/is-type'
import TgApp from './TgApp'


const SplashScreenContext = createContext(null)


function SplashScreen(){

	const [error, setError] = useState(null)

	const [loadedJs, setLoadedJs] = useState(false)
	const [loadedApp, setLoadedApp] = useState(false)
	const loaded = useMemo(() => loadedJs && loadedApp, [loadedJs, loadedApp])


	const loader = useMemo(() => {

		return (
			<div className='loader-container'>
				<div className='loader'>
					<div className='loader__circle' />
				</div>
			</div>
		)

	}, [])


	const contextValue = useMemo(() => (
		{
			error, setError,
			loadedApp, setLoadedApp,
			loader
		}
	), [error, loadedApp, loader])


	const tgAppContent = useMemo(() => <TgApp />, [])
	const content = useMemo(() => {
		if(isStr(error)) return (
			<div className='error-container'>
				<div className='logo'><div /><div className='m28'>Pay</div></div>
				<div className='m24 error__title'>OOPS! Something went wrong</div>
				<div className='m15 error__text'>{error}</div>
			</div>
		)
		return tgAppContent
	}, [error, tgAppContent])


	const initRef = useRef(false)
	useEffect(() => {
		if(!isObj(initRef) || initRef.current !== false) return;
		initRef.current = true

		setTimeout(() => {
			const app = document.getElementById('app')
			const appLoader = document.querySelector('.app-loader')

			app.style.display = 'flex'
			appLoader.style.display = 'none'

			setLoadedJs(true)
		}, 50)

		const html = document.querySelector('html')
		html.scrollTop = 0

		const body = document.querySelector('body')
		body.scrollTop = 0
	}, [])


	if(!loadedJs) return null
	return (
		<div className={`app ${loaded ? '' : 'loading'}`}>
			<SplashScreenContext.Provider value={contextValue}>
				{content}
			</SplashScreenContext.Provider>
		</div>
	)

}


const useSplashScreen = () => useContext(SplashScreenContext)


export default SplashScreen
export { useSplashScreen }