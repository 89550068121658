import { createContext, useContext, cloneElement, useState, useMemo, useCallback } from 'react'
import { ofArr } from '@prefect9/is-type'
import CopyPush from '../components/CopyPush'
import NotificationPush from '../components/NotificationPush'


const PushContext = createContext(null)


function Push({ children }){

	const [list, setList] = useState([])


	const addPush = useCallback(push => setList(state => {
		const result = []
		result.push(push)

		for(let item of ofArr(state)) result.push(item)
		return result
	}), [])

	const deletePush = useCallback(push => setList(state => {
		const result = []

		for(let item of ofArr(state)){
			if(item === push) continue;
			result.push(item)
		}

		return result
	}), [])


	const copy = useCallback((text, message) => {
		const textArea = document.createElement('textarea')
		textArea.value = text
		textArea.style.top = '-9999px'
		textArea.style.left = '-9999px'
		textArea.style.position = 'fixed'

		document.body.appendChild(textArea)
		textArea.focus()
		textArea.select()
		document.execCommand('copy')
		textArea.blur()
		document.body.removeChild(textArea)

		const push = { key:'push_'+String(Math.random()).replace('.', '_'), message }
		const deleteHandler = () => deletePush(push)
		const element = <CopyPush onDelete={deleteHandler} message={message} k={push.key} />
		push.element = element
		addPush(push)
	}, [addPush, deletePush])


	const notification = useCallback((title, description) => {
		const push = { key:'push_'+String(Math.random()).replace('.', '_'), title, description }
		const deleteHandler = () => deletePush(push)
		const element = <NotificationPush onDelete={deleteHandler} title={title} description={description} k={push.key} />
		push.element = element
		addPush(push)
	}, [addPush, deletePush])


	const contextValue = useMemo(() => {
		return { copy, notification, setList }
	}, [copy, notification])

	const content = useMemo(() => {
		const result = []
		for(let index in list){
			const push = list[index]
			const element = push.element
			result.push(cloneElement(element, {...element.props, key:push.key}))
		}
		return result
	}, [list])

	return (
		<PushContext.Provider value={contextValue}>
			{children}
			<div className='push-container'>
				<div className='push-body'>{content}</div>
			</div>
		</PushContext.Provider>
	)

}


const usePush = () => useContext(PushContext)

export default Push
export { usePush }