import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { isStr, isNum } from '@prefect9/is-type'
import DateTime from '@prefect9/datetime'

import { ReactComponent as ArrowBottomCircleSvg } from '../icons/ArrowBottomCircle.svg'


function AccountTransactionDepositUsdtTrc20({ transaction }){

	const deposit = transaction.deposit
	const date = useMemo(() => {
		const result = new DateTime(transaction.date_create)
		return result.toInterfaceStr()
	}, [transaction])

	const [statusClassName, statusText] = useMemo(() => {
		const statuses = {
			confirmation: ['account-transaction__deposit-confirmation', 'Confirmation'],
			confirmed: ['account-transaction__deposit-confirmed', 'Confirmed'],
			error: ['account-transaction__deposit-error', 'Confirmed']
		}
		if(!Object.keys(statuses).includes(deposit.status)) throw new Error(`Not found status ${deposit.status}`)
		return statuses[deposit.status]
	}, [deposit])

	const content = useMemo(() => {
		return (
			<>
				<div className='account-transaction__ico'><ArrowBottomCircleSvg /></div>
				<div className='account-transaction__data'>
					<div className='m14'>Deposit USDT (TRC-20)</div>
					<div className='t10'>{date}</div>
				</div>
				<div className='account-transaction__amount'>
					<div className='m14'>+{deposit.amount} USD</div>
					<div className='t10'>{statusText}</div>
				</div>
			</>
		)
	}, [date, deposit.amount, statusText])


	if(isStr(transaction.notification) || isNum(transaction.notification)) return <Link className={`account-transaction account-transaction__deposit ${statusClassName}`} to={`/notifications/${transaction.notification}`}>{content}</Link>
	return <div className={`account-transaction account-transaction__deposit ${statusClassName}`}>{content}</div>

}
export default AccountTransactionDepositUsdtTrc20