import { Outlet } from 'react-router-dom'
import { AnimatedRouterLoader } from '@prefect9/ui'


function Base(){

	return (
		<>
			<AnimatedRouterLoader />
			<Outlet />
		</>
	)

}
export default Base