import { createContext, useContext, useState, useCallback, useMemo } from 'react'
import { useTgApp } from './TgApp'
import { isObj, isStr, isFunc } from '@prefect9/is-type'


const ModalContext = createContext(null)


function Modal({ children }){
	const { TgApi } = useTgApp()

	const [nowState, setState] = useState(null)


	const showModal = useCallback(renderModal => {
		
		const pageHeaderColor = TgApi.headerColor.now
		TgApi.headerColor.set('#000')

		let resolve, reject
		const promise = new Promise((resl, rej) => {

			const previousHandler = TgApi.backButton.removeHandler()

			const closeModal = () => {
				if(isFunc(previousHandler)) TgApi.backButton.show(previousHandler)

				TgApi.headerColor.set(pageHeaderColor)
				setState(null)
			}

			resolve = resp => {
				closeModal()
				resl(resp)
			}
			reject = e => {
				closeModal()
				rej(e)
			}

		})
		TgApi.backButton.show(() => resolve(null))

		const { modal, className } = renderModal(resolve, reject)

		const newState = { promise, resolve, reject, modal, className }
		setState(newState)

		return promise
	}, [TgApi])


	const contextValue = useMemo(() => {
		return { nowState, showModal }
	}, [nowState, showModal])


	return (
		<ModalContext.Provider value={contextValue}>
			{children}
			<ModalContent state={nowState} />
		</ModalContext.Provider>
	)

}


const useModal = () => useContext(ModalContext)


function ModalContent(){
	const { nowState } = useModal()
	
	return (
		<div className={`modal ${isObj(nowState) ? 'active' : ''} ${isObj(nowState) && isStr(nowState.className) ? nowState.className : ''}`}>
			<div>
				{isObj(nowState) ? nowState.modal : null}
			</div>
		</div>
	)

}


export default Modal
export { useModal }