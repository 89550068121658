import { useMemo, useCallback } from 'react'
import { usePush } from '../lib/Push'
import { isStr, isObj, isNum } from '@prefect9/is-type'

import { ReactComponent as CopySvg } from '../icons/Copy.svg'


function OtpModal({ resolve, reject, bankCardOtp }){
	const { copy } = usePush()


	const copyHandler = useCallback(e => {
		e.preventDefault()

		if(!isObj(bankCardOtp) || !isStr(bankCardOtp.otp)) return;
		copy(bankCardOtp.otp, 'The OTP code was copied')
	}, [copy, bankCardOtp])


	const otpCode = useMemo(() => {
		if(!isObj(bankCardOtp)) return 'N/A'
		const { otp } = bankCardOtp
		if(!isStr(otp)) return 'N/A'
		return `${otp.substr(0,3)} ${otp.substr(3,3)}`
	}, [bankCardOtp])

	const chargeCard = useMemo(() => {
		if(!isObj(bankCardOtp)) return 'N/A'
		const { bank_card:bankCard } = bankCardOtp
		if(!isObj(bankCard) || !isNum(bankCard.last4)) return 'N/A'
		return `•• ${bankCard.last4}`
	}, [bankCardOtp])

	const amount = useMemo(() => {
		if(!isObj(bankCardOtp)) return 'N/A'
		const { amount, currency } = bankCardOtp
		if(!isStr(amount) || !isStr(currency)) return 'N/A'
		return `${amount} ${currency}`
	}, [bankCardOtp])

	const merchantName = useMemo(() => {
		if(!isObj(bankCardOtp)) return 'N/A'
		const { merchant_name:merchantName } = bankCardOtp
		if(!isStr(merchantName)) return 'N/A'
		return merchantName
	}, [bankCardOtp])

	return (
		<div className='modal-body'>
			<div className='modal__title m20'>OTP Code</div>
			<div className='modal-otp-code__container' onClick={copyHandler}>
				<div>{otpCode}</div>
				<CopySvg />
			</div>
			<div className='modal-otp-code__line t12'>
				<div>Charge card:</div>
				<div>{chargeCard}</div>
			</div>
			<div className='modal-otp-code__line t12'>
				<div>Amount:</div>
				<div>{amount}</div>
			</div>
			<div className='modal-otp-code__line t12'>
				<div>Merchant:</div>
				<div>{merchantName}</div>
			</div>
			<button className='modal-btn btn gray-black' onClick={() => resolve('closed')}>Close</button>
		</div>
	)

}


function renderOtpModal(bankCardOtp){
	return (resolve, reject) => ({ modal: <OtpModal resolve={resolve} reject={reject} bankCardOtp={bankCardOtp} />, className:'modal-otp-code' })
}


export default renderOtpModal