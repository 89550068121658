function AccountTransactionsLoader(){

	return (
		<div className='list-loading'>
			<div className='main-transaction-loader'>
				<div className='main-transaction-loader__ico' />
				<div className='main-transaction-loader__data'><div /><div /></div>
				<div className='main-transaction-loader__amount'><div /><div /></div>
			</div>
			<div className='main-transaction-loader'>
				<div className='main-transaction-loader__ico' />
				<div className='main-transaction-loader__data'><div /><div /></div>
				<div className='main-transaction-loader__amount'><div /><div /></div>
			</div>
			<div className='main-transaction-loader'>
				<div className='main-transaction-loader__ico' />
				<div className='main-transaction-loader__data'><div /><div /></div>
				<div className='main-transaction-loader__amount'><div /><div /></div>
			</div>
			<div className='main-transaction-loader'>
				<div className='main-transaction-loader__ico' />
				<div className='main-transaction-loader__data'><div /><div /></div>
				<div className='main-transaction-loader__amount'><div /><div /></div>
			</div>
			<div className='main-transaction-loader'>
				<div className='main-transaction-loader__ico' />
				<div className='main-transaction-loader__data'><div /><div /></div>
				<div className='main-transaction-loader__amount'><div /><div /></div>
			</div>
		</div>
	)

}
export default AccountTransactionsLoader