import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { isObj, isStr, isNum } from '@prefect9/is-type'
import Num from '@prefect9/num'
import DateTime from '@prefect9/datetime'

import { ReactComponent as ArrowsTopBottomSvg } from '../icons/ArrowsTopBottom.svg'


function AccountTransactionBankCardTransfer({ transaction }){

	const bankCardTransfer = useMemo(() => {
		if(!isObj(transaction.bank_card_transfer) || transaction.bank_card_transfer.object !== 'bank_card_transfer') throw new Error('Invalid bank card transfer object')
		return transaction.bank_card_transfer
	}, [transaction])

	const [statusClassName, name, amount] = useMemo(() => {
		if(bankCardTransfer.from === 'account' && bankCardTransfer.to === 'card'){

			if(!isObj(bankCardTransfer.bank_card) || bankCardTransfer.bank_card.object !== 'bank_card') throw new Error('Invalid bank card object')
			const bankCard = bankCardTransfer.bank_card

			return ['account-transaction__transfer-minus', `Transfer to card #${bankCard.last4}`, `-${Num.toAmount(bankCardTransfer.amount)} USD`]

		}else if(bankCardTransfer.from === 'card' && bankCardTransfer.to === 'account'){

			if(!isObj(bankCardTransfer.bank_card) || bankCardTransfer.bank_card.object !== 'bank_card') throw new Error('Invalid bank card object')
			const bankCard = bankCardTransfer.bank_card

			return ['account-transaction__transfer-plus', `Transfer from card #${bankCard.last4}`, `+${Num.toAmount(bankCardTransfer.amount)} USD`]

		}else throw new Error('Invalid transfer direction')
	}, [bankCardTransfer])

	const date = useMemo(() => {
		const result = new DateTime(transaction.date_create)
		return result.toInterfaceStr()
	}, [transaction])


	const content = useMemo(() => {
		return (
			<>
				<div className='account-transaction__ico'><ArrowsTopBottomSvg /></div>
				<div className='account-transaction__data'>
					<div className='m14'>{name}</div>
					<div className='t10'>{date}</div>
				</div>
				<div className='account-transaction__amount'>
					<div className='m14'>{amount}</div>
					<div className='t10'>Completed</div>
				</div>
			</>
		)
	}, [name, amount, date])
	if(isStr(transaction.notification) || isNum(transaction.notification)) return <Link className={`account-transaction account-transaction__transfer ${statusClassName}`} to={`/notifications/${transaction.notification}`}>{content}</Link>
	return <div className={`account-transaction account-transaction__transfer ${statusClassName}`}>{content}</div>

}
export default AccountTransactionBankCardTransfer