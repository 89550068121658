import { createContext, useContext, useMemo, useState, useEffect } from 'react'
import { useSplashScreen } from './SplashScreen'
import { useTgApp } from './TgApp'
import Modal from './Modal'
import Push from './Push'
import User from './User'


const StaticDataContext = createContext(null)


function StaticData(){
	const { loader, setError } = useSplashScreen()
	const { TgApi } = useTgApp()



	const [loading, setLoading] = useState(true)
	const [bankCardsLimits, setBankCardsLimits] = useState(null)
	const [bankCardsTariffs, setBankCardsTariffs] = useState(null)

	useEffect(() => {
		TgApi.getStaticData()
		.then(data => {
			setBankCardsLimits(data.bank_cards_limits)
			setBankCardsTariffs(data.bank_cards_tariffs)
			setLoading(false)
		})
		.catch(e => setError('Configuration initialization error'))
	}, [TgApi, setError])


	const contextValue = useMemo(() => ({ bankCardsTariffs, bankCardsLimits }), [bankCardsTariffs, bankCardsLimits])

	const userContent = useMemo(() => <User />, [])
	const modalContent = useMemo(() => <Modal>{userContent}</Modal>, [userContent])
	const pushContent = useMemo(() => <Push>{modalContent}</Push>, [modalContent])

	if(loading) return loader
	return (
		<StaticDataContext.Provider value={contextValue}>
			{pushContent}
		</StaticDataContext.Provider>
	)

}


const useStaticData = () => useContext(StaticDataContext)


export default StaticData
export { useStaticData }




