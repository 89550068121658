import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { isStr, isNum } from '@prefect9/is-type'
import DateTime from '@prefect9/datetime'

import { ReactComponent as ArrowTopCircleSvg } from '../icons/ArrowTopCircle.svg'


function AccountTransactionWithdraw({ transaction }){

	const withdraw = transaction.withdraw
	const date = useMemo(() => {
		const result = new DateTime(transaction.date_create)
		return result.toInterfaceStr()
	}, [transaction])

	const title = useMemo(() => {
		let result = 'Withdraw'
		if(withdraw.method === 'usdt_trc20') result = `Withdraw ${withdraw.wallet.substr(0,3)}...${withdraw.wallet.substr(-6)}`
		return result
	}, [withdraw])

	const [statusClassName, statusText] = useMemo(() => {
		const statuses = {
			wait: ['account-transaction__withdraw-wait', 'Waiting']
		}
		if(!Object.keys(statuses).includes(withdraw.status)) throw new Error(`Not found status ${withdraw.status}`)
		return statuses[withdraw.status]
	}, [withdraw])

	const content = useMemo(() => {
		return (
			<>
				<div className='account-transaction__ico'><ArrowTopCircleSvg /></div>
				<div className='account-transaction__data'>
					<div className='m14'>{title}</div>
					<div className='t10'>{date}</div>
				</div>
				<div className='account-transaction__amount'>
					<div className='m14'>-{withdraw.amount} USD</div>
					<div className='t10'>{statusText}</div>
				</div>
			</>
		)
	}, [title, date, withdraw.amount, statusText])


	if(isStr(transaction.notification) || isNum(transaction.notification)) return <Link className={`account-transaction account-transaction__withdraw ${statusClassName}`} to={`/notifications/${transaction.notification}`}>{content}</Link>
	return <div className={`account-transaction account-transaction__withdraw ${statusClassName}`}>{content}</div>

}
export default AccountTransactionWithdraw