import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import Base from './Base'
import BaseAccount from './BaseAccount'
import ActivationPage from './ActivationPage'
import MainPage from './MainPage'

function Router(){

	const router = createBrowserRouter([
		{
			element: <Base />,
			children: [
				{
				    element: <BaseAccount />,
				    children: [
				    	{
				    		path: '/activation',
				    		element: <ActivationPage />
				    	},
				    	{
				    		path: '/',
				    		element: <MainPage />
				    	},
				    	{
				    		path: '/notifications',
				    		lazy: () => import('./NotificationsPage')
				    	},
				    	{
				    		path: '/notifications/:id',
				    		lazy: () => import('./NotificationPage')
				    	},
				    	{
				    		path: '/profile',
				    		lazy: () => import('./ProfilePage')
				    	},
				    	{
				    		path: '/withdraw',
				    		lazy: () => import('./WithdrawPage')
				    	},
				    	{
				    		path: '/deposit',
				    		lazy: () => import('./DepositPage')
				    	},
				    	{
				    		path: '/deposit/usdt_trc20',
				    		lazy: () => import('./DepositUsdtTrc20Page')
				    	},
				    	{
				    		path: '/cards',
				    		lazy: () => import('./CardsPage')
				    	},
				    	{
				    		path: '/cards/issue',
				    		lazy: () => import('./CardsIssuePage')
				    	},
				    	{
				    		path: '/cards/:id',
				    		lazy: () => import('./CardPage')
				    	}
			    	]
				},
				{
					path: '/terms',
					lazy: () => import('./TermsPage')
				},
				{
					path: '/privacy',
					lazy: () => import('./PrivacyPage')
				}
			]
		}
	])

	return <RouterProvider router={router} />
}

export default Router