import { createContext, useContext, useState, useMemo, useEffect } from 'react'
import { useTgApp } from './TgApp'
import { isStr } from '@prefect9/is-type'
import StaticData from './StaticData'


const ErrorScreenContext = createContext(null)


function ErrorScreen(){
	const { TgApi } = useTgApp()


	const [error, setError] = useState(null)
	useEffect(() => {
		if(!isStr(error)) return;
		TgApi.init()
	}, [error, TgApi])


	const contextValue = useMemo(() => ({ error, setError }), [error])
	const staticDataContent = useMemo(() => <StaticData />, [])


	if(isStr(error)) return (
		<div className='error-container'>
			<div className='logo'><div /><div className='m28'>Pay</div></div>
			<div className='m24 error__title'>OOPS! Something went wrong</div>
			<div className='m15 error__text'>{error}</div>
		</div>
	)
		
	return (
		<ErrorScreenContext.Provider value={contextValue}>
			{staticDataContent}
		</ErrorScreenContext.Provider>
	)
}


const useErrorScreen = () => useContext(ErrorScreenContext)


export default ErrorScreen
export { useErrorScreen }