import { useRef, useEffect } from 'react'
import { isObj } from '@prefect9/is-type'

import { ReactComponent as NotificationSvg } from '../icons/Notification.svg'


function CopyPush({ onDelete:deleteHandler, k, title, description }){
	
	const initRef = useRef(false)
	const pushRef = useRef(null)
	useEffect(() => {
		if(!isObj(pushRef) || !isObj(pushRef.current)) return;
		const push = pushRef.current

		if(!isObj(initRef) || initRef.current !== false) return;
		initRef.current = true

		setTimeout(() => push.classList.add('show'), 10)
		setTimeout(() => push.classList.add('hide'), 3500)
		setTimeout(deleteHandler, 3900)
	}, [k, deleteHandler])


	return (
		<div ref={pushRef} className='push push-notification'>
			<NotificationSvg />
			<div className='push-data'>
				<div className='m14'>{title}</div>
				<div className='t12'>{description}</div>
			</div>
		</div>
	)

}
export default CopyPush