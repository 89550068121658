import { useState, useMemo, useEffect, useCallback } from 'react'
import { useTgApp } from '../lib/TgApp'
import { useNotifications } from '../lib/Notifications'
import { ParseApiCatch } from '@prefect9/helpers'
import AccountTransactionsLoader from './AccountTransactionsLoader'
import AccountTransactionWithdraw from './AccountTransactionWithdraw'
import AccountTransactionDepositUsdtTrc20 from './AccountTransactionDepositUsdtTrc20'
import AccountTransactionBankCardIssue from './AccountTransactionBankCardIssue'
import AccountTransactionBankCardTransfer from './AccountTransactionBankCardTransfer'


function AccountTransactions(){
	const { TgApi } = useTgApp()
	const { addHandler:addNotificationsHandler, removeHandler:removeNotificationsHandler } = useNotifications()

	const [transactions, setTransactions] = useState([])
	const [loading, setLoading] = useState(true)


	const refresh = useCallback(() => {
		setLoading(true)

		TgApi.getTransactions()
		.then(data => {
			setTransactions(data.list)
			setLoading(false)
		})
		.catch(e => {
			ParseApiCatch(e, null, alert)
		})
	}, [TgApi])

	useEffect(refresh, [refresh])


	// подписка на уведомления
	useEffect(() => {
		const notificationHandler = notification => {
			const { type } = notification
			if([
				'withdraw_created',
				'withdraw_declined',
				'withdraw_completed',
				'deposit_usdt_trc20_confirmation',
				'deposit_usdt_trc20_confirmed',
				'deposit_usdt_trc20_error',
				'bank_card_issue',
				'bank_card_transfer'
			].includes(type)) refresh()
		}
		addNotificationsHandler(notificationHandler)

		return () => {
			removeNotificationsHandler(notificationHandler)
		}
	}, [refresh, addNotificationsHandler, removeNotificationsHandler])


	const content = useMemo(() => {
		if(loading) return null

		const result = []
		for(let index in transactions){
			const transaction = transactions[index]

			if(transaction.type === 'withdraw') result.push(<AccountTransactionWithdraw key={index} transaction={transaction} />)
			else if(transaction.type === 'deposit_usdt_trc20') result.push(<AccountTransactionDepositUsdtTrc20 key={index} transaction={transaction} />)
			else if(transaction.type === 'bank_card_issue') result.push(<AccountTransactionBankCardIssue key={index} transaction={transaction} />)
			else if(transaction.type === 'bank_card_transfer') result.push(<AccountTransactionBankCardTransfer key={index} transaction={transaction} />)
			else throw new Error(`Invalid transaction ${transaction.type}`)
		}

		if(result.length < 1) return <div className='list-empty m12'>Your transactions will be here</div>
		return <div className='list-body'>{result}</div>
	}, [loading, transactions])


	return (
		<div className='list'>
			<div className='list-title m13'>Account transactions</div>
			{loading ? <AccountTransactionsLoader /> : content}
		</div>
	)

}
export default AccountTransactions